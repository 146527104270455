import React from "react";
import { useTypewriter, Cursor } from "react-simple-typewriter";
import Media from "./Media";

const LeftBanner = () => {
  const [text] = useTypewriter({
    words: ["Front End Developer", "Software Developer", "UI Designer"],
    loop: true,
    typeSpeed: 20,
    deleteSpeed: 10,
    delaySpeed: 2000,
  });
  return (
    <div className="w-full lgl:w-1/2 flex flex-col gap-20">
      <div className="flex flex-col gap-5">
        <h4 className=" text-lg font-normal">Discover My Work</h4>
        <h1 className="text-6xl font-bold text-white">
          {" "}
          <span className="text-designColor capitalize">Anteneh Kasse</span>
        </h1>
        <h2 className="text-4xl font-bold text-white">
          <span>{text}</span>
          <Cursor
            cursorBlinking="false"
            cursorStyle="|"
            cursorColor="#ff014f"
          />
        </h2>
        <p className="text-base font-bodyFont leading-6 tracking-wide">
          Full Stack Developer with a strong focus on front-end technologies. My
          journey has enabled me to merge technical skills with creativity,
          allowing me to design intuitive and engaging user interfaces. Whether
          working on a mobile app or a web app, I prioritize creating
          responsive, seamless experiences that balance both functionality and
          aesthetics.
        </p>
      </div>
      {/* Media */}
      <Media />
    </div>
  );
};

export default LeftBanner;
