import { AiFillAppstore } from "react-icons/ai";
import { FaMobile, FaGlobe } from "react-icons/fa";
import { SiProgress, SiAntdesign } from "react-icons/si";
import { RiPaintBrushFill } from "react-icons/ri"; // New icon for Graphics Design

// Features Data
export const featuresData = [
  {
    id: 1,
    icon: <RiPaintBrushFill />, // Added icon for Graphics Design
    title: "Graphics Design",
    des: "Crafting visually striking designs, including logos, posters, and digital illustrations, to enhance brand identity and engage your audience.",
  },
  {
    id: 2,
    icon: <AiFillAppstore />,
    title: "App Development",
    des: "Building scalable and interactive applications with a focus on performance, user experience, and seamless integration.",
  },
  {
    id: 3,
    icon: <SiProgress />,
    title: "SEO Optimization",
    des: "Implementing advanced SEO strategies to boost website rankings, drive organic traffic, and improve online visibility.",
  },
  {
    id: 4,
    icon: <FaMobile />,
    title: "Mobile Development",
    des: "Developing high-quality mobile apps for iOS and Android platforms, ensuring a smooth and engaging user experience.",
  },
  {
    id: 5,
    icon: <SiAntdesign />,
    title: "UX Design",
    des: "Creating intuitive and user-centered designs through research, wireframing, and prototyping to enhance user satisfaction.",
  },
  {
    id: 6,
    icon: <FaGlobe />,
    title: "Hosting Websites",
    des: "Providing reliable and secure web hosting services with optimized server performance and 24/7 support.",
  },
];
