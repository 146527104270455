import React from "react";
import Title from "../layouts/Title";
import {
  netflix,
  amazon,
  evangadiForum,
  selamMart,
  projectOne,
  projectThree,
} from "../../assets/index";
import ProjectsCard from "./ProjectsCard";

const Projects = () => {
  return (
    <section
      id="projects"
      className="w-full py-2 border-b-[1px] border-b-black"
    >
      <div className="flex justify-center items-center text-center">
        <Title
          title="Visit my portfolio and share your feedback."
          des="My Projects"
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 xl:gap-14">
        <ProjectsCard
          title="Netflix Clone"
          des="A full-featured Netflix clone with movie streaming and user authentication."
          src={netflix}
          githubLink="https://github.com/Anteneh2323/netflix-clone"
          liveLink="https://anteneh2323.github.io/Netflix-Project"
        />
        <ProjectsCard
          title="Amazon Clone"
          des="A replica of Amazon's e-commerce platform with product listings and cart features."
          src={amazon}
          githubLink="https://github.com/Anteneh2323/amazon-clone"
          liveLink="https://gleeful-marzipan-f224e7.netlify.app"
        />
        <ProjectsCard
          title="Evangadi Forum"
          des="A social media platform for discussion and engagement with various topics."
          src={evangadiForum}
          githubLink="https://github.com/Anteneh2323/Evangadi-Forum-Front-End"
          liveLink="https://evangadi-forum-app-g2.netlify.app/"
        />
        <ProjectsCard
          title="Selam Mart (Restaurant & E-commerce)"
          des="A website that offers restaurant menu browsing, online ordering, and e-commerce functionality."
          src={selamMart}
          githubLink="https://github.com/Anteneh2323/selam-mart"
          liveLink="https://selam-cafe.netlify.app/"
        />
        <ProjectsCard
          title="Exercise App"
          des="A fitness app for tracking workouts, setting goals, and monitoring progress."
          src={projectOne}
          githubLink="https://github.com/Anteneh2323/exercise-app"
          liveLink="https://exercise-app.vercel.app"
        />
        <ProjectsCard
          title="E-commerce Website"
          des="An e-commerce website featuring product browsing, cart functionality, and online payment."
          src={projectThree}
          githubLink="https://github.com/Anteneh2323/ecommerce-website"
          liveLink="https://ecommerce-website.vercel.app"
        />
      </div>
    </section>
  );
};

export default Projects;
