import React from "react";
import {
  FaFacebookF,
  FaTwitter,
  FaLinkedinIn,
  FaGithub,
  FaInstagram,
} from "react-icons/fa";
import { Link } from "react-scroll"; // Import Link from react-scroll
import { logo } from "../../assets/index"; // Ensure logo is imported correctly

const ContactLeft = () => {
  return (
    <div className="w-full lgl:w-[35%] h-full bg-gradient-to-r from-[#1e2024] to-[#23272b] p-4 lgl:p-8 rounded-lg shadow-shadowOne flex flex-col gap-8 justify-center">
      <img
        className="w-full h-67 object-cover rounded-lg mb-2"
        src={logo}
        alt="contactImg"
      />
      <div className="flex flex-col gap-4">
        <h3 className="text-3xl font-bold text-white">Anteneh Kasse</h3>
        <p className="text-lg font-normal text-gray-400">Software Developer</p>
        <p className="text-base text-gray-400 tracking-wide">
          Passionate software developer with a background in architecture and
          design. Skilled in web development, particularly in React, Node.js,
          and full-stack technologies. Always eager to learn and solve complex
          problems with creative solutions.
        </p>
        <p className="text-base text-gray-400 flex items-center gap-2">
          Phone: <span className="text-lightText">+1 737-343-5005</span>
        </p>
        <p className="text-base text-gray-400 flex items-center gap-2">
          Email: <span className="text-lightText">antenehkasse@gmail.com</span>
        </p>
      </div>
      <div className="flex flex-col gap-4">
        <h2 className="text-base uppercase font-titleFont mb-4">Find me in</h2>
        <div className="flex gap-4"></div>
      </div>
    </div>
  );
};

export default ContactLeft;
