import React from "react";
import { BsGithub } from "react-icons/bs";
import { FaGlobe } from "react-icons/fa";

const ProjectsCard = ({ title, des, src, githubLink, liveLink }) => {
  return (
    <div className="w-full p- xl:p-5 h-auto rounded-lg shadow-shadowOne flex flex-col bg-gradient-to-r from-bodyColor to-[#202327] group hover:bg-gradient-to-b hover:from-gray-900 hover:to-gray-900 transition-colors duration-1000">
      {/* Adjusted height for better image coverage */}
      <div className="w-full h-64 overflow-hidden rounded-lg">
        <img
          className="w-full h-full object-cover group-hover:scale-105 duration-300 cursor-pointer"
          src={src}
          alt={title}
        />
      </div>
      <div className="w-full mt-4 flex flex-col gap-4">
        <div>
          <div className="flex items-center justify-between">
            <h3 className="text-base uppercase text-designColor font-semibold">
              {title}
            </h3>
            <div className="flex gap-3">
              {githubLink && (
                <a
                  href={githubLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-lg w-9 h-9 rounded-full bg-gray-800 inline-flex justify-center items-center text-gray-400 hover:text-designColor transition duration-300 cursor-pointer"
                >
                  <BsGithub />
                </a>
              )}
              {liveLink && (
                <a
                  href={liveLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-lg w-9 h-9 rounded-full bg-gray-800 inline-flex justify-center items-center text-gray-400 hover:text-designColor transition duration-300 cursor-pointer"
                >
                  <FaGlobe />
                </a>
              )}
            </div>
          </div>
          <p className="text-sm tracking-wide mt-2 hover:text-gray-100 duration-300">
            {des}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ProjectsCard;
