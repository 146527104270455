import React from "react";
import { motion } from "framer-motion";
import ResumeCard from "./ResumeCard";

const Education = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      className="w-full flex flex-col lgl:flex-row gap-10 lgl:gap-20"
    >
      {/* part one - Bachelor of Science in Architecture */}
      <div>
        <div className="py-6 lgl:py-12 font-titleFont flex flex-col gap-4">
          <p className="text-sm text-designColor tracking-[4px]">2019</p>
          <h2 className="text-3xl md:text-4xl font-bold">
            Bachelor of Science | Architecture
          </h2>
        </div>
        <div className="mt-6 lgl:mt-14 w-full h-[1000px] border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10">
          <ResumeCard
            title="Bachelor of Science in Architecture"
            subTitle="University of Gondar, Ethiopia"
            result="2019"
            des="Graduated with comprehensive knowledge in architectural design, planning, and project management."
          />
        </div>
      </div>

      {/* part two - Full Stack Development Bootcamp */}
      <div>
        <div className="py-6 lgl:py-12 font-titleFont flex flex-col gap-4">
          <p className="text-sm text-designColor tracking-[4px]">2024</p>
          <h2 className="text-3xl md:text-4xl font-bold">
            Full Stack Development Bootcamp
          </h2>
        </div>
        <div className="mt-6 lgl:mt-14 w-full h-[1000px] border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10">
          <ResumeCard
            title="Full Stack Development Bootcamp"
            subTitle="Evangadi Tech | Austin ,Texas "
            result="2023"
            des="Gained comprehensive skills in full-stack web development, including proficiency in HTML, CSS, JavaScript, React.js, Node.js, Express.js, and MongoDB."
          />
        </div>
      </div>

      {/* part three - Revature Java Training */}
      <div>
        <div className="py-6 lgl:py-12 font-titleFont flex flex-col gap-4">
          <p className="text-sm text-designColor tracking-[4px]">2024</p>
          <h2 className="text-3xl md:text-4xl font-bold">Java programming</h2>
        </div>
        <div className="mt-6 lgl:mt-14 w-full h-[1000px] border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10">
          <ResumeCard
            title="Revature "
            subTitle="Revature | Online Training (11/2024 - Present)"
            result="2024"
            des="Participating in intensive Java training program designed to develop expertise in Java programming, object-oriented principles, and full-stack development practices."
          />
        </div>
      </div>
    </motion.div>
  );
};

export default Education;
