import React from "react";
import { motion } from "framer-motion";
import ResumeCard from "./ResumeCard";

const Experience = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      className="py-12 font-titleFont flex gap-20"
    >
      <div>
        <div className="flex flex-col gap-4">
          <p className="text-sm text-designColor tracking-[4px]">
            2023 - Present
          </p>
          <h2 className="text-4xl font-bold">Professional Experience</h2>
        </div>
        <div className="mt-14 w-full h-[1000px] border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10">
          <ResumeCard
            title="Freelance Web and App Developer"
            subTitle="Self-Employed | Austin, TX - (08/2023 - Present)"
            result="Austin, TX"
            des="Designed and developed a responsive restaurant website using Next.js and Node.js, ensuring a seamless user experience across devices. Integrated payment processing APIs and secure login authentication. Implemented user interfaces with Tailwind CSS, improving aesthetics and usability."
          />
          <ResumeCard
            title="Graphics Designer"
            subTitle="Zobel PLC | Addis Ababa, Ethiopia - (03/2022 - 05/2023)"
            result="Addis Ababa, Ethiopia"
            des="Created visually appealing design solutions for digital and print media. Developed branding projects such as logos, brochures, and marketing materials for clients, enhancing brand visibility. Assisted in designing user interfaces for an e-commerce platform, ensuring intuitive user experience."
          />
          <ResumeCard
            title="Architect"
            subTitle="Tsedeke Tekle Consultant PLC | Gondar, Ethiopia - (04/2019 - 02/2022)"
            result="Gondar, Ethiopia"
            des="Designed and consulted on over 20 mixed-use and apartment complex projects. Collaborated with civil and electrical engineers to deliver design solutions. Used AutoCAD, Autodesk Revit, and Adobe Creative Suite for advanced design and planning."
          />
        </div>
      </div>

      <div>
        <div className="flex flex-col gap-4">
          <p className="text-sm text-designColor tracking-[4px]">2022 - 2023</p>
          <h2 className="text-4xl font-bold">Education & Training</h2>
        </div>
        <div className="mt-14 w-full h-[1000px] border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10">
          <ResumeCard
            title="Full Stack Development Bootcamp"
            subTitle="Evangadi Tech - (12/2022 - 05/2023)"
            result="Evangadi Tech"
            des="Gained comprehensive skills in full-stack web development, including proficiency in HTML, CSS, JavaScript, React.js, Node.js, Express.js, and MongoDB."
          />
          <ResumeCard
            title="Bachelor of Science in Architecture"
            subTitle="University of Gondar, Ethiopia - (2019)"
            result="Gondar, Ethiopia"
            des="Completed a degree in architecture, gaining a deep understanding of design principles and project management, and utilizing advanced design software such as AutoCAD, Autodesk Revit, and Adobe Creative Suite."
          />
        </div>
      </div>
    </motion.div>
  );
};

export default Experience;
